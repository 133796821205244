import {
  LocationGroup,
  USCity,
  USDMA,
  USPostalCode,
  USState,
} from '@lasso/api-activation/activation/legacy'
import { LocationShortInfo, LocationSubType } from '@lasso/api-activation/activation'

import { NormalizedLocation } from './types'

export type NormalizeLocationParam =
  | {
    type: LocationSubType.STATE
    location: USState
  }
  | {
    type: LocationSubType.CITY
    location: USCity
  }
  | {
    type: LocationSubType.DMA
    location: USDMA
  }
  | {
    type: LocationSubType.POSTAL_CODE
    location: USPostalCode
  }
  | {
    type: LocationSubType.LOCATION_GROUP
    location: LocationGroup
  }

export const normalizeListLocation = (param: NormalizeLocationParam): NormalizedLocation => {
  const shared = {
    id: param.location.id,
    type: param.type,
    enabled: true,
  } satisfies Partial<NormalizedLocation>

  switch (param.type) {
    case LocationSubType.STATE:
    case LocationSubType.DMA:
      return {
        ...shared,
        name: param.location.name,
      }

    case LocationSubType.CITY:
      return {
        ...shared,
        name: param.location.displayName,
      }

    case LocationSubType.POSTAL_CODE:
      return {
        ...shared,
        name: param.location.code,
      }

    case LocationSubType.LOCATION_GROUP:
      return {
        ...shared,
        name: param.location.name,
      }
  }
}

export const normalizeSelectedLocation = (location: LocationShortInfo): NormalizedLocation => {
  return {
    // TODO: don't send negative ids from backend, what are you even doing
    id: Math.abs(location.locationId ?? 0),
    name: location.locationName ?? '',
    type: location.subType!,
    enabled: !(location.isExcluded ?? false),
  }
}

export const buildLocationsMap = (locations: NormalizedLocation[]): Map<LocationSubType, Map<number, NormalizedLocation>> => {
  const locationsByTypeById: Map<LocationSubType, Map<number, NormalizedLocation>> = new Map()

  for (const location of locations) {
    const { type, id } = location

    if (!locationsByTypeById.has(type)) {
      locationsByTypeById.set(type, new Map())
    }

    locationsByTypeById.get(type)!.set(id, location)
  }

  return locationsByTypeById
}
