import { LocationSubType } from '@lasso/api-activation/activation'
import { useApi } from '@lasso/shared/hooks'
import {
  useActivationLegacyApi,
} from '@lasso/api-activation/activation/legacy'
import { arrayIncludes, objFromEntries, objValues, reversedDebouncedRef, truthy } from '@lasso/shared/utils'
import { RefOrGetter } from '@lasso/shared/types'
import { Ref, computed, reactive, toRef, toValue } from 'vue'

import { NormalizedLocation } from './types'
import { NormalizeLocationParam, normalizeListLocation } from './utils'

const filteredOnBackend = [
  LocationSubType.CITY,
  LocationSubType.DMA,
  LocationSubType.POSTAL_CODE,
] as const

export const useLocationLists = ({ isCardEnabled, shouldFetchByAccountId, marketerId, accountId, visitedTabs }: {
  isCardEnabled: RefOrGetter<boolean>
  shouldFetchByAccountId: boolean

  marketerId: Readonly<Ref<number>>
  accountId: Readonly<Ref<number>>

  visitedTabs: Set<LocationSubType>
}) => {
  const legacyApi = useActivationLegacyApi()

  const searchQueries: Record<LocationSubType, string> = reactive(
    objFromEntries(
      objValues(LocationSubType).map(type => [type, '']),
    ),
  )

  const searchQueriesDebounced: Record<LocationSubType, string> = reactive(
    objFromEntries(
      objValues(LocationSubType).map((type) => {
        const query = arrayIncludes(filteredOnBackend, type)
          ? reversedDebouncedRef(toRef(searchQueries, type), 300)
          : toRef(searchQueries, type)

        return [type, query]
      }),
    ),
  )

  const apis = reactive({
    [LocationSubType.STATE]: useApi(
      legacyApi.locations.getUSStatesList,
      () => (toValue(isCardEnabled) && visitedTabs.has(LocationSubType.STATE)) ? [] : null,
      { refetch: true, clearWhenDisabled: true, cache: true },
    ),
    [LocationSubType.LOCATION_GROUP]: useApi(
      legacyApi.locations.getLocationGroups,
      () => {
        return (toValue(isCardEnabled) && visitedTabs.has(LocationSubType.LOCATION_GROUP))
          ? [{ marketerId: marketerId.value, accountId: shouldFetchByAccountId ? accountId.value : 0 }]
          : null
      },
      { refetch: true, clearWhenDisabled: true, cache: true },
    ),
    [LocationSubType.CITY]: useApi(
      legacyApi.locations.getUSCitiesList,
      () => {
        const query = searchQueries[LocationSubType.CITY].trim()

        return (toValue(isCardEnabled) && query) ? [query] : null
      },
      { refetch: true, clearWhenDisabled: true, cache: true },
    ),
    [LocationSubType.DMA]: useApi(
      legacyApi.locations.getUSDMAList,
      () => {
        const query = searchQueries[LocationSubType.DMA].trim()

        return (toValue(isCardEnabled) && query) ? [query] : null
      },
      { refetch: true, clearWhenDisabled: true, cache: true },
    ),
    [LocationSubType.POSTAL_CODE]: useApi(
      legacyApi.locations.getUSPostalCodesList,
      () => {
        const codes = searchQueries[LocationSubType.POSTAL_CODE]
          .trim()
          .split(/,|\r?\n/)
          .map(item => item.trim())
          .filter(truthy)

        return (toValue(isCardEnabled) && codes.length > 0) ? [codes] : null
      },
      { refetch: true, clearWhenDisabled: true, cache: true },
    ),
  } as const) satisfies Record<LocationSubType, unknown>

  const getNormalizedLocations = (type: LocationSubType): NormalizedLocation[] => {
    const locations = apis[type].data?.data ?? []

    return locations.map(location => normalizeListLocation({ location, type } as NormalizeLocationParam))
  }

  const locationLists: Record<LocationSubType, NormalizedLocation[]> = reactive({
    [LocationSubType.STATE]: computed(() => {
      const normalizedQuery = searchQueries[LocationSubType.STATE].trim().toLocaleLowerCase()
      const locations = getNormalizedLocations(LocationSubType.STATE)

      return locations.filter(item => item.name.toLocaleLowerCase().includes(normalizedQuery))
    }),
    [LocationSubType.LOCATION_GROUP]: computed(() => {
      const normalizedQuery = searchQueries[LocationSubType.LOCATION_GROUP].trim().toLocaleLowerCase()
      const locations = getNormalizedLocations(LocationSubType.LOCATION_GROUP)

      return locations.filter((item) => {
        return item.name.toLocaleLowerCase().includes(normalizedQuery) || String(item.id).includes(normalizedQuery)
      })
    }),
    ...objFromEntries(
      filteredOnBackend.map(type => [
        type,
        computed(() => getNormalizedLocations(type)),
      ]),
    ),
  })

  const isListLoading = (type: LocationSubType): boolean => {
    return apis[type].loading
  }

  const isListEmpty = (type: LocationSubType): boolean => {
    return !apis[type].loading && locationLists[type].length === 0
  }

  const isListNoToggle = (type: LocationSubType): boolean => {
    return type === LocationSubType.POSTAL_CODE
  }

  const getListPlaceholderIcon = (type: LocationSubType) => {
    if (!arrayIncludes(filteredOnBackend, type)) {
      return undefined
    }

    return searchQueries[type].trim() ? undefined : 'search'
  }

  const getListPlaceholder = (type: LocationSubType, title: string): string | undefined => {
    if (!arrayIncludes(filteredOnBackend, type) || searchQueries[type].trim()) {
      return undefined
    }

    return `To get started, search for a ${title}`
  }

  const getListError = (type: LocationSubType) => {
    return apis[type].error
  }

  const requestList = (type: LocationSubType) => {
    void apis[type].request()
  }

  return {
    apis,
    searchQueries: searchQueriesDebounced,

    locationLists,
    isListLoading,
    isListEmpty,
    isListNoToggle,
    getListPlaceholder,
    getListPlaceholderIcon,
    getListError,
    requestList,
  }
}
